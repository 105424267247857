import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    }),
);

export default function Projects() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>Regression Dashboard</Typography>
                    <Typography className={classes.secondaryHeading}>Regression tests management dashboard</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Tools: Scala Play 2.8, ReactJS 17, sbt, Docker, Bamboo, BitBucket, Rancher.
                        The dashboard allows to monitor regression test suites execution from Bamboo. It adds the convenience of being able to monitor plans running on different branches of the test framework which is not that well supported by Bamboo. Users may also create and schedule regression orders by picking plans to run/services to check and setting the scheduled time.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className={classes.heading}>Personal Website</Typography>
                    <Typography className={classes.secondaryHeading}>
                        This website :)
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Tools: ReactJS 17, TypeScript, MaterialUI, AWS Route 53, AWS Amplify
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}