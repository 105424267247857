import React from 'react';

interface HomeProps {

}

interface HomeState {

}

class Home extends React.Component<HomeProps, HomeState> {
    state = {}

    render() {
        return (
            <div>
                Hi! My name is Vitalii Kalinichev and I'm a Quality Engineer in Test
            </div>
        );
    }
}


export default Home;
